.fonts{
    color: rgb(184, 137, 8);
    font-size: 65px;
}
.fonts h1{
    font-size: 32px;
    padding: 14px;
}

 h6 {
    color: rgb(184, 137, 8);
}

.a{
    color: rgb(184, 137, 8);
}

.colorsb{
    color: rgb(184, 137, 8);
}

.colorsb a{
    color: rgb(184, 137, 8);
}

.fixedimage{
    height:  370px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-image: url(https://i.postimg.cc/fyqg60x0/180329133-115155367377937-3539408854166716095-n.jpg);
    background-attachment: fixed;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bookbutton{
    background-color: #b27f09; /* Green */
  border: none;
  color: white;
  padding: 8px 19px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 12px;
}