.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



a {
  text-decoration: none !important; 
}

.container{
  font-family: 'Titillium Web', sans-serif;
}

.fonts h2{
  font-family: 'Titillium Web', sans-serif;
 
}

.texts{
  text-align: justify;
  text-justify: inter-word;
}



.conference{
  background-color: #b27f09; /* Green */
border: none;
color: white;
padding: 6px 19px;
text-align: center;
text-decoration: none;
display: inline-block;
font-size: 15px;
border-radius: 6px;
}

.button12{
  background-color: #3b3a39; /* Green */
  border: none;
  color: white;
  padding: 6px 22px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  border-radius: 6px;
}

.button13{
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}