.btn-newbutton{
    background-color: #b27f09; /* Green */
  border: none;
  color: white;
  padding: 8px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 12px;
}

.krishnagarhotel
{
  background-color: rgb(213, 216, 220);
}